.collections-list {
    @include breakpoint( medium down ) {
        @include flex-align( $x: center );
    }
}

.collections-list-item {

    .asset-box {
        width: 100%;
    }

    .image-placeholder {
        position: relative;
        // Acts as a persistent placeholder for the image, set to a fixed aspect ratio of 3:2. Override on the element
        // for customized ratios defined by the user (CMS setting).
        padding-bottom: 66.66666666666666666666%;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            object-fit: cover;

            // Prevent background bleed in case we use a border-radius, see
            // https://css-tricks.com/almanac/properties/b/border-radius/
            //background-clip: padding-box;

            &.has-shadow {
                @include default-shadow;
            }
        }

    }

    .title {
        a {
            color: inherit;
        }
    }

    div:last-child > .title {
        // Distance to list item below, in case there is no description (otherwise, the margin is set on .read-more)
        // Margin doesn't work here, apparently because the .title is contained in a flex item
        padding-bottom: 3 * $global-margin;
    }

    .description {
        margin-top: 0.25 * $global-margin;
    }

    .read-more {
        margin-bottom: 2 * $global-margin;
    }

}

