// Feed data
body.feed {
    // Page intro header only, without page intro text
    .intro h1:only-child {
        margin-bottom: $global-margin;
    }

    .flexible-content {
        margin-bottom: 2 * $global-margin;
    }
}

.feed-entry {
    margin-bottom: 2 * $global-margin;

    .post-date {
        font-weight: 200;
        color: $dark-gray;
    }

    .cover-image img {
        width: 100%;
    }

    .post-title {
        &, a {
            color: $black;
        }
    }

    .author {
        font-style: italic;
    }
}

