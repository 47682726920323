// Typography

// --------------------
// Helpers
// --------------------

// These styles are explicit - when the class is applied, it must be effective, without the option of being overridden.
// So they are indeed !important. See also https://css-tricks.com/when-using-important-is-the-right-choice/
// (If you need to override these styles, just don't apply them with a class.)
.small-caps, .smallcaps {
    font-variant: small-caps !important;
}

.uc, .upper-case, .caps {
    text-transform: uppercase !important;
}

.em, .i {
    font-style: italic !important;
}

.strong, .b {
    font-weight: bold !important;
}

.non-bold, .normal-weight {
    font-weight: normal !important;
}

.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;
}

.text-full, .text-justify {
    text-align: justify !important;
}

