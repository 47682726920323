// The media block component (gallery blocks, video blocks)
.media-block {

    // NB max-width constraints are set dynamically, derived from the carousel and its aspect ratio. See the
    // gallery JS (part of the gallery page template).

    margin-left: auto;
    margin-right: auto;
    margin-top: $media-block-top-spacing;

    clear: both;

    &.placed-full {
        margin-top: $media-block-full-width-vertical-spacing;
        margin-bottom: $media-block-full-width-vertical-spacing;

        &.as-paragraph {
            margin-top: 0;
            margin-bottom: $paragraph-margin-bottom;
        }

        &.as-box {
            margin-top: $media-block-full-width-boxed-vertical-spacing;
            margin-bottom: $media-block-full-width-boxed-vertical-spacing;
        }
    }

    &.placed-center {
        margin-top: $media-block-centered-vertical-spacing;
        margin-bottom: $media-block-centered-vertical-spacing;

        // NB &.as-paragraph:
        // placed-center does not support as-paragraph. as-paragraph only makes sense for full-width content, which
        // could potentially double as a real, pre-manufactured paragraph.

        &.as-box {
            margin-top: $media-block-centered-boxed-vertical-spacing;
            margin-bottom: $media-block-centered-boxed-vertical-spacing;
        }
    }

    &.placed-full, &.placed-center {

        // Prevent media info text (headline, description) from interfering with these margins. Otherwise, they would be
        // added to the total margin when floating and clearing is involved.
        .media-info {
            > *:first-child, > *:first-child > *:first-child {                                                          // sass-lint:disable-line nesting-depth
                margin-top: 0;
            }

            > *:last-child, > *:last-child > *:last-child {                                                             // sass-lint:disable-line nesting-depth
                margin-bottom: 0;
            }
        }

        // Creating more space between the nav bar and the carousel on a gallery page (kind of a hack). We set a
        // distance which is equal to the margin-top of an h1 header (1.5rem).
        @at-root #main > &:first-child {                                                                                // sass-lint:disable-line no-invalid-hex
            margin-top: 1.5 * $global-margin;
        }

        // Display the media block as a visible box, distinct from the page background, if desired
        //
        // NB As of now, as-box only supports full-width or centered media blocks. In order to apply it to media blocks
        // floating at the side, they would need a major overhaul.
        &.as-box {
            @if $media-block-background-enabled {
                @include frosted-glass( $media-block-background-opacity-dark, "dark" );

                @at-root body.is-dark & {
                    @include frosted-glass( $media-block-background-opacity-light, "light" );
                }
            }

            border: rem-calc( 1 ) solid $dark-gray;
            padding: $media-block-boxed-padding;
        }

    }

    @include breakpoint( $media-block-float-switch-breakpoint-up ) {

        &.placed-left {
            float: left;
            padding-left: 0;
            padding-right: $media-block-side-spacing;
        }

        &.placed-right {
            float: right;
            padding-left: $media-block-side-spacing;
            padding-right: 0;
        }

    }

    @include breakpoint( $media-block-float-switch-breakpoint-down ) {
        // We need to override any percentage width defined in the backend, which is set as an element style. Likewise
        // for min-width. We need !important for that.
        //
        // NB The defined percentage is not meant to be applied to small screens - we go full width. And min-width must
        // not be allowed to mess with small screens (gallery or video might become too wide for the screen).
        width: 100% !important;
        min-width: 0 !important;

        // Vertical margins for centered media blocks must no longer be different from those of full-width media blocks
        // because in narrow, mobile screens "center" is turned into "full width".
        //
        // NB We can't use !important here because special rules apply to media blocks right below the navigation (see
        // the hack a bit above), and they must still be applied. Instead, we use :not( dummy class ) to increase
        // specificity just enough.
        &:not( .as-paragraph ):not( .dummy-class-for-enhancing-specificity ) {
            margin-top: $media-block-full-width-vertical-spacing;
            margin-bottom: $media-block-full-width-vertical-spacing;

            &.as-box {
                margin-top: $media-block-full-width-boxed-vertical-spacing;
                margin-bottom: $media-block-full-width-boxed-vertical-spacing;
            }
        }
    }

    .carousel-container {
        margin-left: auto;
        margin-right: auto;
    }

    &:not( .single-column-only ) {

        @include breakpoint( $media-block-layout-switch-breakpoint ) {
            display: flex;

            align-content: flex-start;

            // Content justification matters when the media info, e.g. a gallery headline and description, is placed
            // side-by-side with the media element block, and the media element block is not floated.
            //
            // So we need to consider a gallery on a wide screen, and with the single-column layout mode inactive. That
            // results in a side-by-side layout of media info and media element block. The ideal setting for content
            // justification depends on which of the following edge cases needs to be covered:
            //
            // - `center`:
            //   + Works when there isn't any media info data, ie when there is neither a headline nor a description.
            //     In case a max-width limit kicks in, the media element is centred, which is what we want.
            //   + Does not work when there is only a headline, and that headline is very short. The entire side-by-side
            //     block of media info and media element no longer consumes the available width, and whitespace appears
            //     along the outer edges. This looks weird.
            //
            // - `space-between`:
            //   The situation is reversed.
            //   + When there is neither headline nor description, and a max-width constraint kicks in, the media
            //     element is left-aligned rather than centred, with lots of whitespace to the right. Looks bad.
            //   + When there is just a very short headline, it is aligned to the left edge, and the media element is
            //     aligned to the right edge. This is what we want. There is just a bit of extra space in the middle,
            //     between the two areas, which is the lesser evil and looks decent enough.
            //
            // - When there is a headline or a description, and either of them is long enough to stretch out to the
            //   entire width allocated to it, both values work the same.
            justify-content: center;

            &.has-headline, &.has-description {
                justify-content: space-between;
            }

            // Aligning the media element with the top of the text to its side.
            //
            // A tiny top margin needs to be set, equal in size to the space inside the line box above the text, which
            // is generated by the line height. That empty space above equals `excess white space in line box / 2`, or
            // more precisely `( ( line-height factor - 1 ) * text size ) / 2`. We express the result as a fraction of
            // the global margin, ie we replace `text size` with `some factor * global margin`.
            &.inline-media.has-headline .media-element {
                // Aligning the media element with a headline. Based on line-height 1.4, font-size 1rem, global-margin
                // 1rem.
                margin-top: 0.2 * $global-margin;
            }

            &.inline-media.has-description:not( .has-headline ) .media-element {
                // Aligning the media element with paragraph text. Based on line-height 1.6, font-size 1rem,
                // global-margin 1rem.
                margin-top: 0.3 * $global-margin;
            }

            .carousel-container {
                margin-left: 0;
                margin-right: 0;
            }

            .fit-height-to-window {

                .carousel-container {
                    margin-left: auto;
                }

            }

            &:not( .has-description ):not( .has-headline ):not( .carousel-fullscreen ) {

                .fit-height-to-window {

                    .carousel-container {
                        margin-right: auto;
                    }

                }

            }

            .media-info {
                padding-right: $media-block-side-spacing;
                max-width: 33.3333%;
            }

            &.media-left:not( .carousel-fullscreen ) {
                flex-direction: row-reverse;

                .fit-height-to-window {

                    .carousel-container {
                        margin-left: 0;
                        margin-right: auto;
                    }

                }

                .media-info {
                    padding-right: 0;
                    padding-left: $media-block-side-spacing;
                }
            }

            // This class creates a floating media element block, with the title and media description flowing around
            // it. But the elegance comes at a price: the media element needs to get a fixed width (in percent). The
            // remaining width is filled with the media title and description.
            //
            // By contrast, in "normal" mode, without floating, the media element block does not have a set width. It
            // scales and expands until the space left by the media info column is used up. The info column, however,
            // has explicit width rules. That way, the column can use a max-width and adjust to its content: if there is
            // just a short title and nothing else, the column is narrow and the media element block expands. If there
            // is a long title, or description text, it fills the column and expands it to its max-width, while the
            // media element block shrinks accordingly. The info column drives the layout.
            //
            // With a float, the situation is reversed. The media info column is set to full width (100%) of the
            // container, and the media element block must carve out its own little corner, floating inside the media
            // info column, and define and "sustain" its own width. Its size can no longer be driven by its surroundings
            // (ie, the media info column). Hence, we have to sacrifice the flexibility of the "normal" mode and tie
            // down the width of the media element block.
            //
            // That's why "float" mode should only be applied when strictly necessary, ie when a description is present -
            // implying that there is enough text to use up the width left unused by the media element block. If there
            // would just be a short title, without a description, we'd end up with large gap between title and media
            // element block because the media element block no longer expands. That kind of logic is beyond (S)CSS
            // capabilities, however, so it resides in the gallery-block/video-block templates.
            &.floating-fixed-width-media-element:not( .carousel-fullscreen ) {
                @include clearfix;
                display: block;

                .media-element {
                    float: right;
                    width: 66.6667%;

                    box-sizing: content-box;
                    padding-left: $media-block-side-spacing;
                }

                &.media-left .media-element {
                    float: left;
                    padding-left: 0;
                    padding-right: $media-block-side-spacing;
                }

                .media-info {
                    padding-right: 0;
                    padding-left: 0;
                    max-width: none;
                }
            }
        }

    }

    // Creating more space between the nav bar and the carousel on a gallery page. If a headline or a description is
    // next to the gallery, it generates the same amount of space above it as well (made up of the top part of the line
    // box, generated by a line height > 1; margin-top doesn't matter because it is reset to 0). The margin-top for the
    // media element is set accordingly, so the headline, or the description, and gallery are aligned. See above
    // (carousel alignment) for the math.
    &:not( .inline-media ) .media-element {
        // Based on h1 margin-top 0 (native margin-top is reset to 0 by a rule above), line-height 1.4, font-size
        // 2.25 rem, global margin 1rem. Desired photo margin is line height compensation 0.45rem, rounded to 0.5rem
        // for better visual harmony when switching to single-column view. We set it as a padding because in single-
        // column view, the margin does not have an effect.
        padding-top: 0.5 * $global-padding;
    }

    // Creating a top margin above the gallery description in case the headline is missing. NB Inline galleries are
    // dealt with separately, see above. The value is calculated to place the upper text edge in the same position as
    // headline text (compensating for differences in the height of the line box).
    &:not( .inline-media ).has-description:not( .has-headline ) .media-info {
        // Based on p line height 1.6, font size 1rem. From that, the space generated above is 0.3rem, but needs to be
        // increased to 0.45rem (increase rounded up to +0.2rem) to match the top space in the h1 line box. We do that
        // with a padding because in single-column view, the margin does not have an effect. (NB We don't need to add
        // the generic h1 top margin of 1.5rem because it would have been reset to 0 by a rule above anyway).
        padding-top: 0.2 * $global-padding;
    }

    .media-element {

        // NB
        // - Max heights are enforced indirectly with max-width constraints. These are set dynamically, derived from
        //   the carousel and its aspect ratio. See the gallery JS (responsive-gallery.js).
        // - We don't enforce max height limits for videos, so we only need the JS-based dance for gallery carousels.

        // NB flex-basis defaults to "take up the full width of the container, or anyway as much as you can". Is
        // overridden as soon as there is content to the side of it (a headline or a description) - see below.
        flex-basis: 100%;
        order: 1;

        // Default margin-bottom, applies to video. For galleries, we need a larger margin to provide space for the dots
        // and the full-screen button.
        margin-bottom: $global-margin;

        &.gallery-media-element {
            // We split up the space into padding-bottom and margin-bottom. The padding part adds a fixed, unchanging
            // amount of space for the dots to whatever margin is computed below it. The margin part, however, interacts
            // (collapses) with the margin on the outer .media-block container, which crates distance to the content
            // following the gallery when the gallery position is `full` or `center`.
            padding-bottom: $global-margin;
            margin-bottom: 2 * $global-margin;
        }

        &.fit-height-to-window {
            max-height: 70vh;
        }

        // Hide the jumps and readjustments during setup. This class is removed when the setup is done.
        &.is-initializing {
            visibility: hidden;
        }

    }

    // A flex-basis constraint for the media element defines how the total available space is distributed between the
    // media element and the media info (headline, description) when they appear side-by-side.
    //
    // NB The constraint must not be applied if there isn't any media info. The media element must be allowed to expand
    // fully then. The setting is only active if the .media-block is set to `display: flex` (handled conditionally,
    // elsewhere).
    &.has-headline .media-element, &.has-description .media-element {
        flex-basis: 78%;
    }

    // Full-screen mode when the gallery does NOT expand to cover the whole window (fits inside window, no cropping)
    // For a fully expanded gallery, with images covering the entire window, see _carousel.scss.
    &.carousel-fullscreen .media-element, .media-element.carousel-fullscreen {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        max-height: 100%;
        margin: 0;
        padding: 2%;

        z-index: 5;

        background-color: $white;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .carousel-container {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
    }

}
