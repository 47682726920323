// Configuration for the media block component (gallery blocks, video blocks)

$media-block-layout-switch-breakpoint: large;
$media-block-float-switch-breakpoint-up: smedium;
$media-block-float-switch-breakpoint-down: msmall down;

$media-block-top-spacing: 0.25 * $global-margin;                        // Compromise, works for approximate alignment next to headlines or paragraph text.
$media-block-side-spacing: 3 * $global-padding;                         // e.g. when floating left or right

$media-block-full-width-vertical-spacing: 3 * $global-padding;          // Space above and below the block when its position is set to "full"
$media-block-full-width-boxed-vertical-spacing: 2.5 * $global-padding;  // Space above and below the block, with position "full" and also as-box
$media-block-centered-vertical-spacing: 2.5 * $global-padding;          // Space above and below the block when its position is set to "center"
$media-block-centered-boxed-vertical-spacing: 2 * $global-padding;      // Space above and below the block, with position "center" and also as-box

$media-block-boxed-padding: 1.75 * $global-padding;                     // When displayed inside visible box: Space inside the box, in all directions

$media-block-background-enabled: false;                                 // Hard-coded switch to add a semi-transparent background to `as-box` media blocks
$media-block-background-opacity-dark: 0.075;
$media-block-background-opacity-light: 0.05;
