// Specific page components which are a fixed part of the page (as opposed to a flexible-content block)

// Page intro, list intro etc
.intro h1:only-child {
    margin-bottom: 2 * $global-margin;
}

.intro-text {
    margin-bottom: 1.5 * $global-margin;
}

// Page outro
.outro-text {
    margin-bottom: $global-margin;
}