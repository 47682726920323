// About page
.about {

    .contact-methods {
        @include plain-table();

        td {
            padding-top: rem-calc( 4 );
            padding-bottom: 0;
        }

        .type {
            padding-right: rem-calc( 20 );
        }
    }

}
