html, body {
    // Limit height to screen when the class matches (must match on **both** tags)
    &.home {
        height: 100%;
    }
}

.home #footer {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 3;

    .grid-container {
        margin-bottom: rem-calc( 22 );
    }
}
