// stylelint-disable unit-allowed-list

// --------------------
// Typography Styles
// --------------------

// Styles are stored in abstracted form in a global $typography-styles map:
//
// [typography-style-name]: ( default-font-stack: ..., header-styles: ..., body-styles: ... )
//
// A typographic style can be applied in two ways.
//
// - Styles can be emitted in a style sheet, e.g. inside a class or element style, by calling the typography-styles()
//   function: `@include typography-styles( name-of-typography-style );`. The output can be narrowed down to a specific
//   element type, e.g. h2. See _typography-tools for this and other typography mixins.
//
// - Styles are also associated with a class name. Assigning that class to an element applies the typographic style. If
//   you switch typography in a nested element, keep in mind that a more specific declaration on the page level may
//   still override it. If that is an issue, don't just assign the class in the HTML, but also make that class extend
//   the typographic base class: e.g. `div.baseline-typography { @extend .baseline-typography; }`.

// Baseline

// Headers:      see config/foundation/_settings $header-* variables
// Regular text: see config/foundation/_settings $body-font-family

// Baseline styles (the default styles) are defined in config/foundation/settings. They can be accessed through
// typography mixins and functions. The baseline styles can also be applied with the .baseline-typography class (or by
// extending the .baseline-typography class).
//
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//
// ATTN Not all baseline style properties get applied as a system-wide default - if you rely only on Foundation!
//
// If global properties differ between headers (e.g. h1 has a font-weight different from h2, or a different font-family),
// the values defined per header WON'T be applied system-wide by default! Only font-size and margins are propagated
// reliably.
//
// => It is not enough to rely on Foundation base styles. That's why we also emit the styles explicitly into the body
//    tag here.
//
// => That results in a slightly HIGHER SPECIFICITY of the base styles. Instead of just being defined e.g. for `h1`,
//    they are now assigned to `body h1`.
//
//    - You can still override them globally with another typography class - just set it on the body tag.
//    - Any other custom styles are also likely nested into at least a class. In addition, they are defined later than
//      the baseline styles in the CSS, so they can always be expected to "win".
//
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
.baseline-typography {

    // Here, the default styles, defined in config/foundation/settings, are copied to the $typography-styles map, so they
    // can be accessed through typography mixins and functions.
    $style-map: (
        default-font-stack: $header-font-family,
        header-styles: $header-styles,
        body-styles: (
            font-family: $body-font-family,
            font-size: 1rem,
            color: $body-font-color
        )
    );

    // Add styles to $typography-map
    @include add-typography-style( baseline, $style-map );

    // Emit styles here
    @include typography-styles( baseline );

}

body {

    // Emit baseline styles here, too
    @include typography-styles( baseline );

}

// Menu
.main-menu-typography {

    $menu-font-family: "Open Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    $menu-logo-font-family: $menu-font-family;

    $style-map: (
        default-font-stack: $menu-font-family,

        header-styles: (
            small: (
                // Style for homepage menu entry (if text-based instead of image- or SVG-based)
                h1: ( font-size: 17, font-family: $menu-logo-font-family ),
                // Style for other top-level menu entries
                h2: ( font-size: 17 ),
                // Styles for nested menu entries (unused)
                h3: ( font-size: 17 ),
                h4: ( font-size: 17 ),
                h5: ( font-size: 17 ),
                // Style for "More"-Button
                h6: ( font-size: 17 ),
            ),
            medium: (
                // Style for homepage menu entry (if text-based instead of image- or SVG-based)
                h1: ( font-size: 20 ),
                // Style for other top-level menu entries
                h2: ( font-size: 20 ),
                // Styles for nested menu entries (unused)
                h3: ( font-size: 20 ),
                h4: ( font-size: 20 ),
                h5: ( font-size: 20 ),
                // Style for "More"-Button
                h6: ( font-size: 20 ),
            ),
        ),

        body-styles: ()
    );

    // Add styles to $typography-map
    @include add-typography-style( main-menu, $style-map );

    // Emit styles here
    @include typography-styles( main-menu );

}

.off-canvas-menu-typography {

    $style-map: (
        // We need to wrap the font stack in parentheses because otherwise, font names would be interpreted as property
        // names of the style map, rather than a (sub-)list
        default-font-stack: ( Montserrat, "Open Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif ),

        header-styles: (
            small: (
                // Style for homepage menu entry
                h1: ( font-size: 17, font-weight: bold ),
                // Style for other top-level menu entries
                h2: ( font-size: 17 ),
                // Styles for nested menu entries (unused)
                h3: ( font-size: 17 ),
                h4: ( font-size: 17 ),
                h5: ( font-size: 17 ),
                // unused
                h6: ( font-size: 17 ),
            ),
            medium: (
                // Style for homepage menu entry
                h1: ( font-size: 20 ),
                // Style for other top-level menu entries
                h2: ( font-size: 20 ),
                // Styles for nested menu entries (unused)
                h3: ( font-size: 20 ),
                h4: ( font-size: 20 ),
                h5: ( font-size: 20 ),
                // unused
                h6: ( font-size: 20 ),
            ),
        ),

        body-styles: ()
    );

    // Add styles to $typography-map
    @include add-typography-style( off-canvas-menu, $style-map );

    // Emit styles here
    @include typography-styles( off-canvas-menu );

}

// Film

.film-typography {

    // Font stack for small-ish headers, on small screens (all others use the default font stack)
    $film-small-header-font-family: Montserrat, "Open Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;

    $style-map: (
        // We need to wrap the font stack in parentheses because otherwise, font names would be interpreted as property
        // names of the style map, rather than a (sub-)list
        default-font-stack: ( Quicksand, Montserrat, sans-serif ),

        header-styles: (
            small: (
                h1: ( font-size: 22, text-transform: uppercase ),
                h2: ( font-size: 20, text-transform: uppercase ),
                h3: ( font-size: 19, font-family: $film-small-header-font-family ),
                h4: ( font-size: 18, font-family: $film-small-header-font-family ),
                h5: ( font-size: 17, font-family: $film-small-header-font-family ),
                h6: ( font-size: 16, font-family: $film-small-header-font-family ),
            ),
            medium: (
                h1: (
                    font-size: 36,
                    margin-top: 3 * $header-margin-bottom,
                    margin-bottom: 2 * $header-margin-bottom
                ),
                h2: ( font-size: 31 ),
                h3: ( font-size: 31 ),
                h4: ( font-size: 25 ),
                h5: ( font-size: 20 ),
                h6: ( font-size: 16 ),
            ),
        ),

        body-styles: (
            font-family: $body-font-family
        )
    );

    // Add styles to $typography-map
    @include add-typography-style( film, $style-map );

    // Emit styles here
    @include typography-styles( film );

}

// About

// Neutral

.neutral-typography {

    $style-map: (
        // We need to wrap the font stack in parentheses because otherwise, font names would be interpreted as property
        // names of the style map, rather than a (sub-)list
        default-font-stack: ( Arial, sans-serif ),

        header-styles: (
            small: (
                h1: ( font-size: 22 ),
                h2: ( font-size: 20 ),
                h3: ( font-size: 19 ),
                h4: ( font-size: 18 ),
                h5: ( font-size: 17 ),
                h6: ( font-size: 16 ),
            ),
            medium: (
                h1: ( font-size: 48 ),
                h2: ( font-size: 40 ),
                h3: ( font-size: 31 ),
                h4: ( font-size: 25 ),
                h5: ( font-size: 20 ),
                h6: ( font-size: 16 ),
            )
        ),

        body-styles: ()
    );

    // Add styles to $typography-map
    @include add-typography-style( neutral, $style-map );

    // Emit styles here
    @include typography-styles( neutral );

}
